<template>
  <AdminPageFrame>
      <PageTitle>
        {{ trans('PAGE_TITLE:PartnerCodes') }}
      </PageTitle>
      <Loader v-if="partnerCodesDataLoading" class="h-050screen"/>
      <t-card v-else class="w-full">
        <div class="w-full lg:min-w-modal-gallery" v-if="partnerCodesData">
          <DataTable :data="partnerCodesData" :headers="tableHeaders" @edit="editPartnerCode" @delete="deletePartnerCode">
            <template #percentDays="{item}">
              {{ item.percentDays + '%' }}
            </template>
          </DataTable>
          <t-pagination
              class="mt-3"
              variant="posts"
              :total-items="pagination.total"
              :per-page="pagination.perPage"
              :limit="5"
              v-model="currentPage"
              @change="next"
          ></t-pagination>
        </div>
      </t-card>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import Button from "@/utils/crud/components/Button";
import DataTable from "@/utils/table/DataTable";
import Loader from "@/views/Loader";
import Modal from "@/utils/modal/Modal";
import PageTitle from "@/layouts/components/pages/PageTitle";
import Pagination from "@/views/content/components/Pagination";
import router from "@/router";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PartnerCodesList",
  components: {
    AdminPageFrame,
    Button,
    DataTable,
    Loader,
    Modal,
    PageTitle,
    Pagination,
  },
  data() {
    return {
      tableHeaders: [
        {
          name: 'LABEL:Actions',
          value: 'actions',
        },        {
          name: 'ID',
          value: 'id'
        },
        {
          name: 'LABEL:Code',
          value: 'code'
        },
        {
          name: 'LABEL:Partner',
          value: 'partnerName',
        },
        {
          name: 'LABEL:Percent',
          value: 'percentDays',
        },
        {
          name: 'LABEL:ValidFrom',
          value: 'validFrom',
        },
        {
          name: 'LABEL:ValidTo',
          value: 'validTo'
        },
        {
          name: 'LABEL:Limit',
          value: 'limit',
        },
      ],
      currentPage: 1,
      isShowDeletePartnerCodeModal: false,
      partnerCodeToDelete: null,
    }
  },
  watch: {
    partnerCodeDeleted(val) {
      if(val) {
        this.partnerCodeToDelete = null;
        this.isShowDeletePartnerCodeModal = false;
        this.currentPage = 1;
      }
    }
  },
  computed: {
    ...mapGetters('partnerCodes', ['partnerCodesData','partnerCodesDataLoading', 'pagination', 'partnerCodeDeleting', 'partnerCodeDeleted'])
  },
  methods: {
    ...mapActions('partnerCodes', ['fetchShowPartnerCodeRequest', 'fetchPartnerCodesRequest', 'deletePartnerCodeRequest']),
    next(val) {
      this.currentPage = val;
      this.fetchPartnerCodesRequest({
        page: val,
      });
    },
    deletePartnerCode(id) {
	    this.deletePartnerCodeRequest({id: id})
    },
    editPartnerCode(id) {
      router.push({name: 'admin-partner-codes-show', params: {id: id}})
    },
  },
  mounted() {
    this.fetchPartnerCodesRequest({page: this.currentPage});
  }
}
</script>
